/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { Entity } from './crud.service';


@Injectable({
  providedIn: 'root'
})
export class QRCodeService {

  private inputs: Subject<string> = new Subject();

  get(): Subject<string> {
    return this.inputs;
  }

}
