import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { canActivate as RouteGuardService } from './guard/route-guard.service';
import { canActivate as MonitorGuardService } from './guard/monitor.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'redirect',
    pathMatch: 'full',
  },
  {
    path: 'redirect',
    loadChildren: () => import('./redirect/redirect.module').then( m => m.RedirectPageModule),
    canActivate: RouteGuardService
  },
  {
    path: 'prenotazioni',
    data: {
      roles: ['prenotasi.prenota']
    },
    loadChildren: () => import('./prenotazioni/prenotazioni.module').then( m => m.PrenotazioniPageModule),
    canActivate: RouteGuardService
  },
  {
    path: 'agenda',
    data: {
      roles: ['prenotasi.agenda']
    },
    loadChildren: () => import('./agenda/agenda.module').then( m => m.AgendaPageModule),
    canActivate: RouteGuardService
  },
  // {
  //   path: 'configuratore',
  //   data: {
  //     roles: ['prenotasi.superadmin']
  //   },
  //   loadChildren: () => import('./configuratore/configuratore.module').then( m => m.ConfiguratorePageModule),
  //   canActivate: RouteGuardService
  // },
  // {
  //   path: 'conf-dispositivi',
  //   data: {
  //     roles: ['prenotasi.configura']
  //   },
  //   loadChildren: () => import('./conf-dispositivi/conf-dispositivi.module').then( m => m.ConfDispositiviPageModule ),
  //   canActivate: RouteGuardService
  // },
  // {
  //   path: 'superadmin',
  //   data: {
  //     roles: ['prenotasi.superadmin']
  //   },
  //   loadChildren: () => import('./superadmin/superadmin.module').then( m => m.SuperadminPageModule),
  //   canActivate: RouteGuardService
  // },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  // {
  //   path: 'customer-sat-page',
  //   loadChildren: () => import('./customer-sat-page/customer-sat-page.module').then( m => m.CustomerSatPagePageModule)
  // },
  // {
  //   path: 'redirect',
  //   loadChildren: () => import('./redirect/redirect.module').then( m => m.RedirectPageModule)
  // },
  {
    path: 'sportello',
    data: {
      roles: ['prenotasi.sportello']
    },
    loadChildren: () => import('./sportello/sportello.module').then( m => m.SportelloPageModule),
    canActivate: RouteGuardService
  },
  // {
  //   path: 'conf-dispositivi',
  //   loadChildren: () => import('./conf-dispositivi/conf-dispositivi.module').then( m => m.ConfDispositiviPageModule)
  // },
  {
    path: 'monitor',
    loadChildren: () => import('./monitor/monitor.module').then( m => m.MonitorPageModule),
    canActivate: MonitorGuardService
  },
  {
    path: 'statistiche',
    data: {
      roles: ['prenotasi.statistiche']
    },
    loadChildren: () => import('./statistiche/statistiche.module').then( m => m.StatistichePageModule),
    canActivate: RouteGuardService
  },
  {
    path: 'sedi',
    data: {
      roles: ['prenotasi.configura']
    },
    loadChildren: () => import('./sedi/sedi.module').then( m => m.SediPageModule),
    canActivate: RouteGuardService
  },
  {
    path: 'utenti',
    data: {
      roles: ['prenotasi.gestoreutenti']
    },
    loadChildren: () => import('./utenti/utenti.module').then( m => m.UtentiPageModule),
    canActivate: RouteGuardService
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
